"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { isDateEffectivelyPast } from "../user-profile.util";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Clock } from "@/images/fields/Clock.svg";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { ReactComponent as EnvelopeSimple } from "@/images/side-bar-icons/EnvelopeSimple.svg";
import { ReactComponent as Minus } from "@/images/side-bar-icons/Minus.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { ReactComponent as Cancel } from "@/images/side-bar-icons/Reject.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ONBOARDING_USER_STATUS_ROUTE, USER_OFFBOARDING_ROUTE } from "@/lib/routes";
import { AccountStatus, copyInviteLink, getInviteLink, inviteUser } from "@/lib/users";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { OffboardingDrawer } from "@/v2/feature/offboarding/components/offboarding-drawer.component";
import { OnboardNewEmployee } from "@/v2/feature/onboarding/components/onboard-new-employee.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useUserProfileConfig } from "@/v2/feature/user/features/user-profile/user-profile.hook";
import { UserProfileActionModal } from "@/v2/feature/user/features/user-profile-action/components/user-profile-action-modal.component";
import { UserProfileDeactivateModal } from "@/v2/feature/user/features/user-profile-action/components/user-profile-deactivate-modal.component";
import { UserProfileDeleteModal } from "@/v2/feature/user/features/user-profile-action/components/user-profile-delete-modal.component";
import { UserProfileActionAPI } from "@/v2/feature/user/features/user-profile-action/user-profile-action.api";
import {
  REGULAR_INVITE_STATUSES
} from "@/v2/feature/user/features/user-profile-action/user-profile-action.interface";
import { UserAPI } from "@/v2/feature/user/user.api";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { createDateAsUTC } from "@/v2/util/date-format.util";
const iconSize = { width: "12px", height: "12px" };
export const UserProfileAction = ({ userId, finallyRefreshUserObject }) => {
  const [deactivationDate, setDeactivationDate] = useState(null);
  const [actionMode, setActionMode] = useState();
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const isAdmin = hasScopes(["user:all"], { userId });
  const hasDevicesRead = hasScopes(["devices:read"], { userId });
  const OFFBOARD_DEACTIVATE_STATUSES = [
    AccountStatus.Created,
    AccountStatus.Invited,
    AccountStatus.Active,
    AccountStatus.InvitedToOnboard
  ];
  const [userDeviceDetails, setUserDeviceDetails] = useState([]);
  const [userDeviceOrders, setUserDeviceOrders] = useState([]);
  const [userAppAccounts, setUserAppAccounts] = useState([]);
  const [userInPayrun, setUserInPayrun] = useState(false);
  const [userIsBillingContact, setUserIsBillingContact] = useState(false);
  const [hasPayments, setHasPayments] = useState(false);
  const [deletionBlocked, setDeletionBlocked] = useState(false);
  const [deletionChecksLoading, setDeletionChecksLoading] = useState(false);
  const [deactivationChecksLoading, setDeactivationChecksLoading] = useState(false);
  const [deactivationBlocked, setDeactivationBlocked] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [deactivateUserModalOpen, setDeactivateUserModalOpen] = useState(false);
  const { userOverviewConfig } = useUserProfileConfig(userId);
  const [onboardingUser, setOnboardingUser] = useState();
  const onboardNewUser = useRef();
  const [offboardingModalOpen, setOffboardingModalOpen] = useState(false);
  const [isOnboardNewUserOpen, setOnboardNewUserOpen] = useState(false);
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const fetchDeletionBlockers = () => __async(void 0, null, function* () {
    try {
      if (isAdmin) {
        setDeletionChecksLoading(true);
        const {
          assignedDevices,
          activeDeviceOrders,
          appAccounts,
          payrollPayrunMembership,
          isBillingContact,
          hasPayments: hasPayments2
        } = yield UserProfileActionAPI.getDeletionChecks(userId);
        setUserDeviceDetails(hasDevicesRead ? assignedDevices : []);
        setUserDeviceOrders(hasDevicesRead ? activeDeviceOrders : []);
        setUserAppAccounts(appAccounts);
        setUserInPayrun(payrollPayrunMembership.inPayrun);
        setUserIsBillingContact(isBillingContact);
        setHasPayments(hasPayments2);
        setDeletionBlocked(
          assignedDevices.length > 0 || activeDeviceOrders.length > 0 || appAccounts.length > 0 || payrollPayrunMembership.inPayrun || isBillingContact
        );
      }
    } catch (error) {
      console.error("Encountered an error while trying to fetch deletion checks for user: ", error);
    } finally {
      setDeletionChecksLoading(false);
    }
  });
  const fetchDeactivationBlockers = () => __async(void 0, null, function* () {
    try {
      if (isAdmin) {
        setDeactivationChecksLoading(true);
        const { assignedDevices, activeDeviceOrders } = yield UserProfileActionAPI.getDeactivationChecks(userId);
        setUserDeviceDetails(hasDevicesRead ? assignedDevices : []);
        setUserDeviceOrders(hasDevicesRead ? activeDeviceOrders : []);
        setDeactivationBlocked(assignedDevices.length > 0 || activeDeviceOrders.length > 0);
      }
    } catch (error) {
      console.error("Encountered an error while trying to fetch deactivation checks for user: ", error);
    } finally {
      setDeactivationChecksLoading(false);
    }
  });
  const checkPasswordExists = useCallback(() => __async(void 0, null, function* () {
    const passwordExists = yield OnboardingAPI.checkPasswordExists(userId);
    setIsPasswordSet(passwordExists);
  }), [userId]);
  useEffect(() => {
    if ([AccountStatus.Invited, AccountStatus.InvitedToOnboard].includes(userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus)) {
      checkPasswordExists();
    }
  }, [checkPasswordExists, userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus]);
  const deleteProfileHandler = () => __async(void 0, null, function* () {
    fetchDeletionBlockers();
    setDeleteUserModalOpen(true);
  });
  const deactivationProfileHandler = () => __async(void 0, null, function* () {
    fetchDeactivationBlockers();
    setDeactivateUserModalOpen(true);
  });
  const openNewUserDrawer = useCallback(
    (userId2) => __async(void 0, null, function* () {
      onboardNewUser.current = userId2 ? getCachedUserById(userId2) : void 0;
      const additionalUserInfoForOnboarding = userId2 ? yield UserAPI.getUserSummaryForOnboardingById(userId2, false) : {};
      onboardNewUser.current = onboardNewUser.current ? __spreadValues(__spreadValues({}, onboardNewUser.current), additionalUserInfoForOnboarding) : onboardNewUser.current;
      setOnboardNewUserOpen(true);
    }),
    [getCachedUserById]
  );
  const headerOptions = [
    {
      title: polyglot.t("UserProfileAction.workflows"),
      options: [
        ...(userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus) === "Created" ? [
          {
            icon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
            handler: () => {
              if (!(onboardingUser == null ? void 0 : onboardingUser.template)) {
                openNewUserDrawer(userId);
                return;
              }
              routerHistory.push(generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId }));
            },
            label: polyglot.t("UserProfileAction.onboard"),
            disabled: false
          }
        ] : [],
        ...OFFBOARD_DEACTIVATE_STATUSES.includes(userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus) ? [
          {
            icon: /* @__PURE__ */ jsx(Minus, __spreadValues({}, iconSize)),
            handler: () => __async(void 0, null, function* () {
              return yield offboard();
            }),
            label: polyglot.t("UserProfileAction.offboard"),
            disabled: false
          }
        ] : [],
        ...Boolean([AccountStatus.InvitedToOnboard].includes(userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus) && isPasswordSet) ? [
          {
            icon: /* @__PURE__ */ jsx(Clock, __spreadValues({}, iconSize)),
            handler: () => routerHistory.push(generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId })),
            label: polyglot.t("UserProfileAction.progress"),
            disabled: false
          }
        ] : []
      ]
    },
    {
      title: polyglot.t("UserProfileAction.access"),
      options: [
        ...Boolean(
          [AccountStatus.Invited, AccountStatus.InvitedToOnboard].includes(userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus) && !isPasswordSet
        ) ? [
          {
            icon: /* @__PURE__ */ jsx(EnvelopeSimple, __spreadValues({}, iconSize)),
            handler: () => __async(void 0, null, function* () {
              return yield inviteUserWrapper();
            }),
            label: polyglot.t("UserProfileAction.sendInvite"),
            disabled: false
          },
          {
            icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
            handler: () => __async(void 0, null, function* () {
              return yield copyInviteLinkHandler();
            }),
            label: polyglot.t("General.copyInvite"),
            disabled: false
          }
        ] : [],
        ...OFFBOARD_DEACTIVATE_STATUSES.includes(userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus) ? [
          {
            icon: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey })),
            handler: () => deactivationProfileHandler(),
            label: polyglot.t("UserProfileAction.deactivate"),
            disabled: false
          }
        ] : [],
        {
          icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
          handler: () => deleteProfileHandler(),
          label: polyglot.t("General.delete"),
          disabled: userDeviceDetails.length > 0
        },
        ...deactivationDate && (userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus) !== "Deactivated" && !isDateEffectivelyPast(deactivationDate) ? [
          {
            icon: /* @__PURE__ */ jsx(Cancel, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
            handler: () => setActionMode("cancelDeactivate"),
            label: polyglot.t("UserProfileAction.cancelDeactivation"),
            disabled: false
          }
        ] : [],
        ...deactivationDate && isDateEffectivelyPast(deactivationDate) || (userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus) === AccountStatus.Deactivated ? [
          {
            icon: /* @__PURE__ */ jsx(Cancel, __spreadValues({}, iconSize)),
            handler: () => setActionMode("reactivate"),
            label: polyglot.t("UserProfileAction.reactivate"),
            disabled: false
          }
        ] : []
      ]
    }
  ];
  useEffect(() => {
    (userOverviewConfig == null ? void 0 : userOverviewConfig.deactivationDate) ? setDeactivationDate(createDateAsUTC(userOverviewConfig == null ? void 0 : userOverviewConfig.deactivationDate)) : setDeactivationDate(null);
  }, [userOverviewConfig.deactivationDate, userOverviewConfig.accountStatus, userId]);
  const offboard = () => __async(void 0, null, function* () {
    if (userOverviewConfig.leaveDate)
      routerHistory.push({ pathname: generatePath(USER_OFFBOARDING_ROUTE, { userId }) });
    else setOffboardingModalOpen(true);
  });
  const inviteUserWrapper = useCallback(() => __async(void 0, null, function* () {
    try {
      const mode = REGULAR_INVITE_STATUSES.includes(userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus) ? "invite" : "onboard";
      yield inviteUser(userId, showMessage, polyglot, mode);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [userId, showMessage, polyglot, userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus]);
  const copyInviteLinkHandler = useCallback(() => __async(void 0, null, function* () {
    try {
      const mode = REGULAR_INVITE_STATUSES.includes(userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus) ? "invite" : "onboard";
      const inviteLink = yield getInviteLink(userId, showMessage, polyglot, mode);
      if (!inviteLink) return;
      yield copyInviteLink(inviteLink, showMessage, polyglot);
    } catch (error) {
      showMessage(
        polyglot.t("OnboardingUserInvite.errorMessages.copyInvite", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [userId, showMessage, polyglot, userOverviewConfig == null ? void 0 : userOverviewConfig.accountStatus]);
  useEffect(() => {
    OnboardingAPI.getOnboardingUser(userId).then(setOnboardingUser);
  }, [userId]);
  return /* @__PURE__ */ jsx(Fragment, { children: isAdmin && /* @__PURE__ */ jsxs(ScopesControl, { scopes: ["user:all"], context: scopesContext, children: [
    /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        headerOptions,
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: polyglot.t("UserProfileAction.title"),
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end",
          fullWidth: true
        }
      }
    ),
    /* @__PURE__ */ jsx(
      UserProfileDeleteModal,
      {
        isOpen: deleteUserModalOpen,
        setIsOpen: setDeleteUserModalOpen,
        userId,
        loading: deletionChecksLoading,
        deletionBlocked,
        userDeviceDetails,
        userDeviceOrders,
        userAppAccounts,
        inPayrun: userInPayrun,
        isBillingContact: userIsBillingContact,
        hasPayments
      }
    ),
    /* @__PURE__ */ jsx(
      UserProfileDeactivateModal,
      {
        isOpen: deactivateUserModalOpen,
        setIsOpen: setDeactivateUserModalOpen,
        userId,
        loading: deactivationChecksLoading,
        deactivationBlocked,
        refresh: () => __async(void 0, null, function* () {
          if (finallyRefreshUserObject) yield finallyRefreshUserObject("user.lifecycle");
        })
      }
    ),
    /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.onboard:manager"], context: { userId }, children: /* @__PURE__ */ jsx(OffboardingDrawer, { userId, isOpen: offboardingModalOpen, setIsOpen: setOffboardingModalOpen }) }),
    /* @__PURE__ */ jsx(
      OnboardNewEmployee,
      {
        isOpen: isOnboardNewUserOpen,
        setIsOpen: setOnboardNewUserOpen,
        onClose: () => {
          if (setOnboardNewUserOpen) setOnboardNewUserOpen(false);
        },
        initialValues: onboardNewUser.current,
        onDraftUserCreated: () => setOnboardNewUserOpen(false)
      }
    ),
    /* @__PURE__ */ jsx(
      UserProfileActionModal,
      {
        userId,
        actionMode,
        setActionMode,
        deactivationDate,
        refresh: () => finallyRefreshUserObject ? finallyRefreshUserObject("user.basicInfo") : {}
      }
    )
  ] }, "actions") });
};
