"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class UserAddressAPI {
  static findByUserId(userId) {
    return __async(this, null, function* () {
      return axios.get(`/apiv2/users/${userId}/address`).then(({ data }) => data);
    });
  }
  static findCurrentByUserId(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/address/current`)).data || null;
    });
  }
  static findAllByCompanyId() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/all/addresses`)).data || null;
    });
  }
  static create(userId, userAddress) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/users/${userId}/address`, userAddress)).data;
    });
  }
  static update(userId, update) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/users/${userId}/address/${update.userAddressId}`, update)).data;
    });
  }
  static delete(userId, addressId) {
    return __async(this, null, function* () {
      return axios.delete(`/apiv2/users/${userId}/address/${addressId}`);
    });
  }
  static findSelectedUserById(userIds) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/addresses?ids=${userIds.join(",")}`)).data || null;
    });
  }
}
